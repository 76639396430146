/* eslint-disable prettier/prettier */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'locales/en.json';
import ru from 'locales/ru.json';
import chinese from "locales/chinese.json"
import japanese from "locales/japanese.json"
import spanish from "locales/spanish.json"
import german from "locales/german.json"
import french from "locales/french.json"
import portuguese from "locales/portuguese.json"
import korean from "locales/korean.json"
import italian from "locales/italian.json"

const i18nConfig = i18n.use(initReactI18next).init({
	resources: {
		en: { common: en },
		ru: { common: ru },
		zh: { common: chinese},
		ja: {common: japanese},
		es: {common: spanish},
		de: {common: german},
		fr: {common: french},
		pt: {common: portuguese},
		ko: {common: korean},
		it: {common: italian}
	},
	fallbackLng: 'en',
	debug: true,
	interpolation: {
		escapeValue: false, // React already does escaping
	},
});

export default i18nConfig;
