import React, { ReactElement, ReactNode } from 'react';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	text: ReactNode | ReactElement;
	loading?: boolean;
	className: string;
	icon?: string;
	iconClass?: string;
	textClass?: string;
	padding?: string;
}

export const Button = ({
	text,
	loading,
	className,
	icon,
	iconClass,
	textClass,
	onClick,
	...rest
}: IButtonProps) => {
	return (
		<button
			{...rest}
			className={`flex items-center gap-2 ${className}`}
			onClick={onClick}
		>
			{icon && <img src={icon} alt="icon" className={iconClass} />}
			{(text || loading) && (
				<span className={textClass}>{loading ? 'Loading....' : text}</span>
			)}
		</button>
	);
};

export const Button3d = ({
	text,
	loading,
	className,
	icon,
	iconClass,
	textClass,
	padding,
	disabled,
	onClick,
	...rest
}: IButtonProps) => {
	return (
		<>
			{/* <button
			{...rest}
			disabled={disabled}
			onClick={onClick}
			className={`${
				disabled ? 'button3d-disabled' : 'bg-[#659B06]'
			} pb-[1%] px-1 rounded-[34px] box-border ${className}`}
		>
			<div
				className={`${
					disabled ? 'button3d-disabled' : 'bg-[#84C70D]'
				} w-full h-full pb-[2px] px-[2px] rounded-[34px]  box-border`}
			>
				<div
					className={`${
						disabled
							? 'button3d-disabled text-[#FFFFFF4F]'
							: 'bg-[#659B06] text-[#0B0E21]'
					} w-full h-full rounded-[34px] text-[1rem] flex items-center justify-center gap-1 ${padding} `}
				>
					{icon && <img src={icon} alt="icon" className={iconClass} />}
					<span className={`font-font_grotesk ${textClass}`}>
						{loading ? 'Loading....' : text}
					</span>
				</div>
			</div>
		</button> */}
			<button
				{...rest}
				disabled={disabled}
				onClick={onClick}
				className={`${disabled ? '' : 'button3d-active-shadow'}  ${className}`}

				// className={`${
				// 	disabled ? 'button3d-disabled' : 'button3d-active'
				// } pb-[1%] px-1 rounded-[34px] box-border ${className}`}
			>
				<div
					className={`${
						disabled
							? 'button3d-disabled text-[#FFFFFF4F]'
							: 'button3d-active text-[#0B0E21]'
					} w-full h-full rounded-[34px] text-[1rem] flex items-center justify-center gap-1 ${padding} `}
				>
					{icon && <img src={icon} alt="icon" className={iconClass} />}
					<span
						className={`font-bold ${
							disabled ? 'button3d-disabled-text' : 'button3d-text'
						} ${textClass}`}
					>
						{loading ? 'Loading....' : text}
					</span>
				</div>
			</button>
		</>
	);
};

export const Button3dMini = ({
	text,
	loading,
	className,
	icon,
	iconClass,
	textClass,
	padding,
	disabled,
	onClick,
	...rest
}: IButtonProps) => {
	return (
		<>
			{/* <button
			{...rest}
			disabled={disabled}
			onClick={onClick}
			className={`${
				disabled ? 'button3d-disabled' : 'bg-[#659B06]'
			} pb-[1%] px-1 rounded-[34px] box-border ${className}`}
		>
			<div
				className={`${
					disabled ? 'button3d-disabled' : 'bg-[#84C70D]'
				} w-full h-full pb-[2px] px-[2px] rounded-[34px]  box-border`}
			>
				<div
					className={`${
						disabled
							? 'button3d-disabled text-[#FFFFFF4F]'
							: 'bg-[#659B06] text-[#0B0E21]'
					} w-full h-full rounded-[34px] text-[1rem] flex items-center justify-center gap-1 ${padding} `}
				>
					{icon && <img src={icon} alt="icon" className={iconClass} />}
					<span className={`font-font_grotesk ${textClass}`}>
						{loading ? 'Loading....' : text}
					</span>
				</div>
			</div>
		</button> */}
			<button
				{...rest}
				disabled={disabled}
				onClick={onClick}
				className={`${
					disabled ? '' : 'button3dmini-active-shadow'
				}  ${className}`}
			>
				<div
					className={`${
						disabled
							? 'button3d-disabled text-[#FFFFFF4F]'
							: 'button3d-active text-[#0B0E21]'
					} w-full h-full rounded-[34px] text-[1rem] flex items-center justify-center gap-1 ${padding} `}
				>
					{icon && <img src={icon} alt="icon" className={iconClass} />}
					<span className={`font-semibold button3d-text ${textClass}`}>
						{loading ? 'Loading....' : text}
					</span>
				</div>
			</button>
		</>
	);
};
