import {
	useEffect,
	// useRef,
	InputHTMLAttributes,
	ReactNode,
	useState,
} from 'react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
// import { selectArrow } from 'assets';

export interface TextInputProps
	extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
	passwordInput?: boolean;
	prefixLabel?: any;
	suffixLabel?: ReactNode;
	setValue?: (e?: any) => void;
	required?: boolean;
	isClickable?: boolean;
	label: string;
	labelIcon?: string;
	subLabel?: ReactNode;
	errorMessage?: any;
	inputInfo?: ReactNode;
	icon?: ReactNode;
	iconClick?: () => void;
	prefix?: string;
	onClick?: (e: any) => void;
	onChange?: (e: any) => void;
	options: any;
	selectOption: (e: any) => void;
}
const SearchableCountries = ({
	options,
	label,
	name,
	placeholder,
	isClickable,
	selectOption,
	...rest
}: TextInputProps) => {
	const [query, setQuery] = useState('');
	const [isOpen, setIsOpen] = useState(true);
	const [selectedVal, setSelectedVal] = useState('');
	const [optionsArray, setOptionsArray] = useState([]);
	// const inputRef = useRef(null);

	useEffect(() => {
		setOptionsArray(options);
	}, [options]);

	// useEffect(() => {
	// 	document.addEventListener('click', toggle);
	// 	return () => document.removeEventListener('click', toggle);
	// }, []);

	// function toggle(e: any) {
	// 	if (isClickable !== false) {
	// 		setIsOpen(e && e.target === inputRef.current);
	// 	}
	// }

	const handleChange = (e: any) => {
		const { value } = e.target;
		setQuery(value);

		if (value?.length > 0) {
			handleSearch(value);
		}
		if (value?.length < 1) {
			setOptionsArray(options);
		}
	};

	const handleSearch = async (value: any) => {
		const countryList = options;
		const newList = countryList.filter(
			(item: any) => item?.name.toLowerCase().includes(value.toLowerCase())
		);
		setOptionsArray(newList);
	};

	useEffect(() => {
		setQuery(selectedVal);
	}, [selectedVal]);

	const defaultOption: any = optionsArray?.find(
		(option: any) => option?.code === name
	);

	return (
		<div>
			<div className="w-full flex items-center mb-1">
				<label
					className={`text-[10px] md:text-[14px] capitalize leading-[21px] font-[400] text-grey font-medium `}
				>
					{label}
				</label>
			</div>
			<div className="relative">
				{/* <div className="relative">
					<input
						{...rest}
						readOnly={true}
						ref={inputRef}
						value={selectedVal ? selectedVal : defaultOption?.name}
						placeholder={placeholder}
						autoComplete="off"
						onClick={isClickable === false ? () => {} : toggle}
						className={`w-full h-[56px] rounded-[43px] bg-[#0F121D] outline-0 px-3 text-[0.875rem] text-center font-medium placeholder-[#232838] border-2 border-[#232838] leading-normal font-normal ${
							query ? 'text-white border-[#84C70D]' : 'text-grey'
						}`}
					/>
					<div
						className={`${
							isOpen ? 'rotate-[180]' : 'rotate-[0]'
						} absolute top-[32%] right-[12px] rotate-[180]`}
						onClick={() => setIsOpen(!isOpen)}
						data-testid="select-country-arrow"
					>
						{isClickable !== false && <img src={selectArrow} alt="arrow" />}
					</div>
				</div> */}

				{isOpen && (
					<div className="absolute z-10 w-full py-4 bg-[#0F121D] text-white rounded-lg overflow-scroll max-h-[400px]">
						<div className="mb-4">
							<input
								{...rest}
								type="text"
								readOnly={isClickable === false ? true : false}
								// ref={inputRef}
								value={query ? query : defaultOption?.name}
								placeholder={placeholder}
								name={name}
								autoComplete="off"
								onChange={(e) => handleChange(e)}
								data-testid="search-country-input"
								className={`w-full h-[56px] rounded-[43px] bg-[#0F121D] outline-0 px-3 text-[0.875rem] text-center font-medium placeholder-[#232838] border-2 border-[#232838] leading-normal font-normal ${
									query ? 'text-white border-[#84C70D]' : 'text-grey'
								}`}
							/>
						</div>

						{optionsArray?.length > 0 &&
							optionsArray.map((option: any, index: number) => {
								return (
									<div
										onClick={() => {
											setIsOpen(true);
											selectOption(option);
											setSelectedVal(option.name);
										}}
										key={`${option.name}-${index}`}
										className="flex items-center gap-4 mb-2 px-6 cursor-pointer"
										data-testid="select-a-country"
									>
										<span>{getUnicodeFlagIcon(option?.code)}</span>
										<span>{option.name}</span>
									</div>
								);
							})}
					</div>
				)}
			</div>
		</div>
	);
};

export default SearchableCountries;
