import {
	accountIcon,
	accountIconActive,
	store,
	storeActive,
	playIcon,
	playIconActive,
	gamesIcon,
	gamesIconActive,
	earnIcon,
	earnIconActive,
	// prizesIcon,
	// prizesIconActive,
	// depositIcon,
	// depositIconActive,
	// communityIconActive,
	// communityIconInactive,
	agentIconActive,
	agentIconInactive,
} from 'assets';
import SoundPlayer from 'components/sounds/GameSounds';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { gamesActions } from 'store/slices';
import { _getUser, _isAnEmptyObject } from 'utils';

interface ISidebarProp {
	className?: string;
}

const sidebarMenu = [
	{
		menu: 'play',
		icon: playIcon,
		iconActive: playIconActive,
		route: '/play',
		key: 'sidebar.play',
	},
	{
		menu: 'games',
		icon: gamesIcon,
		iconActive: gamesIconActive,
		route: '/games',
		key: 'sidebar.games',
	},
	{
		menu: 'store',
		icon: store,
		iconActive: storeActive,
		route: '/store',
		key: 'sidebar.store',
	},
	{
		menu: 'earn',
		icon: earnIcon,
		iconActive: earnIconActive,
		route: '/prizes',
		key: 'sidebar.earn',
	},
	{
		menu: 'account',
		icon: accountIcon,
		iconActive: accountIconActive,
		route: '/account',
		key: 'sidebar.account',
	},
];

export const Sidebar = ({ className }: ISidebarProp) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const user = _getUser();
	const [, setOpen] = useState(false);

	useEffect(() => {
		// Add agent path if user is an agent and 'Dashboard' is not already in the menu
		if (
			user &&
			user.isAgent &&
			!sidebarMenu.some((item) => item.menu === 'dashboard')
		) {
			sidebarMenu.push({
				menu: 'dashboard',
				icon: agentIconInactive,
				iconActive: agentIconActive,
				route: '/agent',
				key: 'agent',
			});
		}
	}, [user]);

	const { t } = useTranslation('common');

	const navigateToRoute = (item: any) => {
		if (
			(item.menu === 'account' ||
				item.menu === 'prizes' ||
				item.menu === 'deposit' ||
				item.menu === 'community' ||
				item.menu === 'dashboard') &&
			_isAnEmptyObject(user)
		) {
			setOpen(true);
			return;
		}
		dispatch(gamesActions.addItem(item.menu));
		SoundPlayer.playSound('menuSelect');
		navigate(item.route);
	};

	return (
		<div className={`${className}`}>
			<div className="w-full lg:w-auto lg:mt-[7rem] lg:pl-8 lg:pr-2 flex flex-row lg:flex-col justify-between lg:justify-start gap-2">
				{sidebarMenu.map((item, i) => {
					// if (['dashboard'].includes(item?.menu) && !user?.isAgent) {
					// 	return <></>;
					// }
					return (
						<div
							key={i}
							className={`w-[4.5rem] lg:w-auto h-[4rem] lg:h-auto flex items-center flex-col lg:flex-row gap-0 lg:gap-3 cursor-pointer tb:py-2 lg:mb-4 tb::pl-3 tb:pr-2 rounded-[12px]
              				${
												pathname === '/' && item.menu === 'play'
													? 'lg:bg-bg-greenSecondary tb:bg-transparent'
													: pathname.includes(item.route)
													? 'lg:bg-bg-greenSecondary'
													: ''
											} ${
												item.menu === 'community' && 'hidden lg:flex'
											} box-border
            			`}
							data-testid={item.route}
							onClick={() => {
								SoundPlayer.playSound('menuSelect');
								navigateToRoute(item);
							}}
						>
							<img
								src={
									pathname === '/' && item.menu === 'play'
										? item.iconActive
										: pathname.includes(item.route)
										? item.iconActive
										: item.icon
								}
								alt="icon"
								className="w-[2.5rem] h-[2.5rem]"
							/>
							<span
								className={`flex flex-row text-[0.812rem] lg:text-[1.125rem] font-[500] tb:font-[700] capitalize
            					${
												pathname === '/' && item.menu === 'play'
													? 'text-text-white font-[700]'
													: pathname.includes(item.route)
													? 'text-text-white font-[700]'
													: 'text-text-white font-[500]'
											}
            					`}
							>
								{item.menu === 'marketplace' ? (
									<>
										<span className="lg:block hidden">marketplace</span>
										<span className="lg:hidden block">market</span>
									</>
								) : (
									t(item.key)
								)}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};
