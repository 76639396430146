import { useState, useEffect } from 'react';
import { gameCoin } from 'assets';
import { Button3d } from 'components/button/Button';
import {
	CircularProgressbarWithChildren,
	buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDailyGameChipsCountdownDateTimer } from 'hooks/useCountdownTimer';
import { useTranslation } from 'react-i18next';

export interface Props {
	modalClose: () => void;
	gamecoin: any;
}

export const GameCoinModal = ({ modalClose, gamecoin }: Props) => {
	const { t } = useTranslation('common');
	const { hoursLeft, minutesLeft, secondsLeft } =
		useDailyGameChipsCountdownDateTimer();

	console.log('hoursLeft', hoursLeft);

	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const updateProgress = () => {
			const now = new Date();
			const utcNow: any = new Date(
				now.getUTCFullYear(),
				now.getUTCMonth(),
				now.getUTCDate(),
				now.getUTCHours(),
				now.getUTCMinutes(),
				now.getUTCSeconds()
			);

			const startOfDayUTC: any = new Date(
				Date.UTC(
					utcNow.getUTCFullYear(),
					utcNow.getUTCMonth(),
					utcNow.getUTCDate(),
					0,
					0,
					0
				)
			);
			const endOfDayUTC: any = new Date(
				Date.UTC(
					utcNow.getUTCFullYear(),
					utcNow.getUTCMonth(),
					utcNow.getUTCDate(),
					23,
					59,
					59
				)
			);

			const totalDayTime = endOfDayUTC - startOfDayUTC;
			const timeElapsed = utcNow - startOfDayUTC;

			const progressPercentage = (timeElapsed / totalDayTime) * 100;
			setProgress(progressPercentage);
		};

		// Update the progress immediately and set an interval to update it every second
		updateProgress();
		const interval = setInterval(updateProgress, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="h-full w-full">
			<main className="absolute bottom-0 pb-[3rem] w-full flex flex-col items-center bg-[#0F121D] rounded-tl-[22px] rounded-tr-[22px] border-2 border-[#ffffff] border-opacity-[8%] ">
				<div className="w-full relative max-w-[360px] px-4 md:px-6 mt-[2.5rem] h-full ">
					<div className="absolute mx-auto -top-[6rem] left-0 right-0 flex justify-center">
						<div className="relative w-fit">
							<CircularProgressbarWithChildren
								value={isNaN(progress) ? 0 : progress}
								counterClockwise={true}
								className="w-[8.2rem] h-[8.2rem] relative"
								styles={buildStyles({
									backgroundColor: 'green',
									trailColor: '#2A2D37',
									pathColor: '#84C70D',
								})}
							>
								<img
									src={gameCoin}
									alt="gameCoin"
									className="mx-auto h-[112px] w-[112px]"
								/>
							</CircularProgressbarWithChildren>
						</div>
					</div>

					<div className="mt-[2.5rem] mb-8 text-primary text-center">
						<p className="text-[1.5rem] font-medium mb-3">Game Chips</p>
						<p className="text-[1rem] text-[#FFFFFF99] font-medium">
							{t('coinDetail')}
						</p>
					</div>

					<div className="bg-[#FFFFFF0A] px-4 py-6 border border-[#FFFFFF14] rounded-[12px]">
						<p className="text-[1rem] text-[#FFFFFF80] text-center mb-2">
							Game Chips balance
						</p>
						<div className="flex items-center justify-center gap-3">
							<img
								src={gameCoin}
								alt="gameCoin"
								className="h-[36px] w-[36px]"
							/>
							<span className="font-medium text-[36px] text-primary">
								{gamecoin}
							</span>
						</div>
					</div>

					<p className="text-[#FFFFFF80] text-center my-6">
						Chips balance will reset in:{' '}
						<span className="text-text-orange">{`${hoursLeft}h: ${minutesLeft}m: ${secondsLeft}s`}</span>
					</p>

					<Button3d
						text={'Close'}
						onClick={() => modalClose()}
						disabled={false}
						className={`font-semibold w-full flex items-center justify-center h-[56px] mt-5mb-5`}
					/>
				</div>
			</main>
		</div>
	);
};
