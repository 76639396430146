import { useNavigate } from 'react-router-dom';
import { ModalLayout } from './modalLayout';
import { Button } from 'components/button/Button';
import { odogwuThumbnail, raffleThumbnail, spinThumbnail } from 'assets';

const DemoGameModal = ({
	closeModal,
	data,
	route,
	thumbnail,
}: {
	closeModal: () => void;
	data: any;
	route: 'string';
	thumbnail: any;
}) => {
	const navigate = useNavigate();

	return (
		<ModalLayout onClose={() => closeModal()} showCloseIcon={true}>
			<div className="text-center font-medium">
				<div
					style={{
						background: `url(${
							thumbnail === 'hunter'
								? odogwuThumbnail
								: thumbnail === 'spin'
								? spinThumbnail
								: thumbnail === 'raffle'
								? raffleThumbnail
								: thumbnail
						})`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'top center',
						backgroundSize: 'cover',
					}}
					className="relative p-3 mb-3 rounded-3xl w-[135px] h-[135px] mx-auto"
				></div>

				<h2 className="text-[#FFFFFF] text-[20px] mb-8 font-medium capitalize">
					{data} - Free Mode
				</h2>

				<p className="text-[#848AA0] text-[14px] font-medium mb-4">
					Get started with {data} Free Mode to receive free gems for playing and
					practicing.{' '}
				</p>

				<p className="text-[#FF8B3E] text-[18px] font-medium mb-3">
					Please note that the funds in Free Mode are intended for practice
					purposes only and cannot be withdrawn.
				</p>

				<Button
					text="Enter Free Mode"
					onClick={() => navigate(`/${route}`)}
					className="disabled:bg-opacity-95  btn-primary lg:w-fit w-full flex mx-auto items-center justify-center text-[14px] md:text-[16px] font-[700] bg-primary md:px-16 px-4 rounded-full py-[10px] mt-10 mb-4 "
				/>
			</div>{' '}
		</ModalLayout>
	);
};

export default DemoGameModal;
