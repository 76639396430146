import { apiService } from 'api/axios';

export const getPublicProducts = async () => {
	const response = await apiService().get('/public/products');
	return response;
};

export const getSpinPublicProducts = async () => {
	const response = await apiService({ gameKey: 'spin' }).get(
		'/public/products?gameKey=spin'
	);
	return response;
};

export const getGameData = async () => {
	const response = await apiService().get(`/public/game-center/data`);
	return response;
};

export const getRecentWinners = async () => {
	const response = await apiService().get(`/public/game-center/winners/recent`);
	return response;
};

export const getSpinLeaderboard = async () => {
	const response = await apiService({
		gameKey: 'spin',
		period: 'weekly',
	}).get(`/member/game-center/winners/leaderboard?gameKey=spin&period=weekly`);
	return response;
};

export const getAllSubscriptionsApi = async () => {
	const response = await apiService({
		subscriptionPlanChannel: 'TELEGRAM',
	}).get(`/public/subscription/plan?subscriptionPlanChannel=TELEGRAM`);
	return response;
};

export const getGlobalSubscriptionPriceApi = async (data: any) => {
	const response = await apiService({
		subscriptionPlanId: data?.subscriptionPlanId,
		countryCode: data?.countryCode,
	}).get(
		`/public/subscription/plan/global/price?subscriptionPlanId=${data?.subscriptionPlanId}&&countryCode=${data?.countryCode}`
	);
	return response;
};

export const calculateSubscriptionDiscountApi = async (data: any) => {
	const response = await apiService({
		subscriptionPlanId: data?.subscriptionPlanId,
		subscriptionValidityId: data?.subscriptionValidityId,
	}).get(
		`/member/subscription/calculate/discount?subscriptionPlanId=${data?.subscriptionPlanId}&&subscriptionValidityId=${data?.subscriptionValidityId}`
	);
	return response;
};

export const gamicLeaderboardApi = async () => {
	const response = await apiService().get(`/member/game-center/leader-board`);
	return response;
};

export const topPlayersLeaderboardApi = async (param: string) => {
	const response = await apiService().get(
		`/member/game-center/leader-board/this-week/${param}`
	);
	return response;
};

export const getTelegramSocialTaskApi = async () => {
	const response = await apiService().get('/public/telegram/tasks');
	return response;
};

export const getReferralLeaderboard = async () => {
	const response = await apiService().get(
		`/public/game-center/referral/leaderboard`
	);
	return response;
};

export const getPublicTournamentsApi = async () => {
	const response = await apiService().get(`/public/telegram/tournament`);
	return response;
};

export const getSingleTournamentApi = async ({
	tournamentId,
	memberId,
}: {
	tournamentId: string | null;
	memberId: any;
}) => {
	const response = await apiService().get(
		`/public/telegram/tournament/${tournamentId}/${memberId}`
	);
	return response;
};

export const getTournamentLeaderboardApi = async (data: any) => {
	const response = await apiService({ tournamentId: data?.tournamentId }).get(
		`/public/telegram/tournament/leader-board?tournamentId=${data?.tournamentId}`
	);
	return response;
};
