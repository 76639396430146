import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { _getUser, _isAnEmptyObject } from 'utils';
import GameBanner from 'assets/images/shaggixodumeje.png';
import DailySpinBanner from 'assets/images/daily-free-spin-bg.png';
import MarketItem from 'components/cards/MarketItem';
import { GameListRow } from 'components/landingPageComps/GameListRow';
import { getSubscriptionDetail } from 'api/apiRequest';
import { userActions } from 'store/slices/user/userSlice';
import { gameCenterDataApi } from 'store/slices/gameCenter/gameCenterApi';
import { getAllMarketProductDataApi } from 'store/slices/gameCenter/gameCenterApi';
import { ExclusiveModal } from 'components/modals/homepage-modals/HomepageModal';

export interface IModalProp {
	status: boolean;
	type?: string;
	modalObj?: object;
}

export function GamesPage() {
	const user = _getUser();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation('common');

	const [sortedMarketAssetsData, setSortedMarketAssetsData] = useState([]);
	const [filter] = useState<{ label: string; id: string }>({
		label: 'All',
		id: '',
	});

	const [modal, setModal] = useState({ isOpen: false, type: '', modalObj: {} });
	const modalOpen = (type: string, modalObj?: any) =>
		setModal({ isOpen: true, type: type, modalObj });
	const modalClose = (e?: boolean) =>
		setModal({ isOpen: e || false, type: '', modalObj: {} });

	const { data: gameCenterData } = useQuery(
		['game-center-data'],
		async () => await gameCenterDataApi(),
		{
			retry: 0,
			onSuccess: (res: any) => {
				localStorage.setItem(
					'merchant-domain',
					res?.data?.merchantSetup?.domainName
				);
				localStorage.setItem('hunter-key', res?.data?.hunterConnectionKey);
				localStorage.setItem('spin-key', res?.data?.spinConnectionKey);
				localStorage.setItem(
					'ice-timer',
					res?.data?.merchantSetup?.iceTimerMinutes
				);
			},
		}
	);

	const { data: marketProductData } = useQuery(
		['get-all-market-products', filter],
		async () =>
			await getAllMarketProductDataApi(
				`${filter && `productCategoryId=${filter?.id}`}`
			),
		{
			retry: 0,
		}
	);

	const { data: activeSubDetail } = useQuery(
		['get-sub-detail'],
		async () => await getSubscriptionDetail(),
		{
			onSuccess: (res: any) => {
				const isActiveSubExist = res?.data?.find(
					(item: any) => item?.isActive === true
				);
				dispatch(userActions.addUserSubscription(isActiveSubExist));
			},
			retry: 0,
			enabled: !_isAnEmptyObject(user),
		}
	);

	const isActiveSubExist = activeSubDetail?.data?.find(
		(item: any) => item?.isActive === true
	);

	useEffect(() => {
		// Filtering out Backgrounds
		const filteredMarketProductData = marketProductData?.data?.itemsList.filter(
			(item: any) => item?.gameAssetKey !== 'BackGround'
		);

		setSortedMarketAssetsData(filteredMarketProductData);
	}, [marketProductData]);

	const goToFreeGame = () => {
		if (isActiveSubExist) {
			navigate('/hammer-spin/free-spin');
		} else {
			modalOpen('sub-reminder');
			//unlock exclusive access
		}
	};

	return (
		<div className="relative w-full ">
			<div className="w-full mb-4 px-4">
				<img
					src={GameBanner}
					alt="banner"
					className="rounded-[14px] border border-[#FFFFFF14]"
				/>
			</div>

			<section className="w-full px-[5%] md:px-0 flex flex-col md:flex-row gap-[1rem] lg:gap-[4%] mb-8">
				<div className="w-full md:w-[48%] h-auto mb-3 mt-3">
					<div className="mb-4 flex items-center gap-2">
						<span className="text-[#ffffff] text-[1.5rem] font-semibold">
							{t('games.playGame')}
						</span>
					</div>
					<div className="w-full h-full flex gap-2 ">
						<GameListRow
							data-testid="odogwuGameDemo"
							key={Math.random()}
							name={'Odogwu Hunter'}
							activePlayers={gameCenterData?.data?.liveGames[0]?.activePlayers}
							isGameEnabled={gameCenterData?.data?.liveGames[0]?.isActive}
							thumbnail={
								gameCenterData?.data?.liveGames[0]?.key
									? gameCenterData?.data?.liveGames[0]?.key
									: 'hunter'
							}
							route={'odogwu-hunter'}
							btnText={t('startPlaying')}
							// type="demo"
							className="w-[50%] h-[12rem]"
						/>
						<GameListRow
							data-testid="spinGameDemo"
							key={Math.random()}
							name={'Hammer Spin'}
							activePlayers={gameCenterData?.data?.liveGames[1]?.activePlayers}
							isGameEnabled={gameCenterData?.data?.liveGames[1]?.isActive}
							thumbnail={
								gameCenterData?.data?.liveGames[1]?.key
									? gameCenterData?.data?.liveGames[1]?.key
									: 'spin'
							}
							route={'hammer-spin'}
							btnText={t('startPlaying')}
							// type="demo"
							className="w-[50%] h-[12rem]"
						/>
					</div>
				</div>

				<div
					className="w-full cursor-pointer"
					data-testid="spinGameFree"
					onClick={() => goToFreeGame()}
				>
					<img src={DailySpinBanner} className="w-full" />
				</div>

				<div className="w-full md:w-[48%] h-auto bg-[#FFFFFF0A] rounded-[6px] border border-[#FFFFFF14] p-4 mb-16">
					<div className="mb-6 flex items-center justify-between gap-2">
						<span className="text-[#ffffff] text-[1.5rem] font-semibold capitalize">
							{t('games.gameBoosters')}
						</span>
						<a
							href="/store"
							className="text-[#AAFB6B] text-[1rem] font-semibold capitalize"
						>
							{t('util.viewMore')}
						</a>
					</div>

					{sortedMarketAssetsData?.length > 0 && (
						<div>
							{sortedMarketAssetsData
								?.slice(0, 5)
								.map((item: any, i: number) => (
									<div
										className={i === 2 ? 'mb-5' : 'mb-8'}
										key={`market-asset-${i}`}
									>
										<MarketItem
											id={i + 1}
											product={item}
											isFeatured={item?.featured}
											title={item?.title}
											// isFeatured={i === 0 || i === 3}
											image={item?.productImageURL}
											priceInGameCurrency={item?.unitPrice}
											gameCurrencyName={item?.currencyId?.symbol}
											isTrending={item?.featured}
											listType="trending"
										/>
									</div>
								))}
						</div>
					)}
				</div>
			</section>
			{modal.isOpen && modal.type === 'sub-reminder' && (
				<ExclusiveModal closeModal={modalClose} />
			)}
		</div>
	);
}
