import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	cowryIcon,
	diamond,
	productMinusinactive,
	productPlusinactive,
	walletIcon,
} from 'assets';
import { Button3d } from 'components/button/Button';
// import { Button } from 'components/button/Button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from 'utils';
import successHandler from 'handlers/successHandler';
import errorHandler from 'handlers/errorHandler';
import { purchaseProductApi } from 'api/apiRequest';

interface IProps {
	openModal?: (e: string, modalprop?: any) => void;
	modalProp?: any;
	modalClose?: () => void;
	setActiveTab?: (e: string) => void;
}

export const ConfirmStorePurchase = ({
	openModal,
	modalProp,
	modalClose,
	setActiveTab,
}: IProps) => {
	const navigate = useNavigate();

	const [productQuantity, setProductQuantity] = useState(1);

	const userWallet = useSelector(
		(state: any) => state.userReducer?.userProfile?.gameCurrencyBalance
	);

	const queryClient = useQueryClient();
	const { mutate, isLoading } = useMutation(purchaseProductApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			successHandler(res, true);
			queryClient.invalidateQueries(['all-user-game-asset']);
			queryClient.invalidateQueries(['user-profile']);
			openModal && openModal('purchase-success', modalProp);
		},
		retry: 0,
	});

	const purchaseProduct = () => {
		console.log('purchase item');
		mutate({
			amount: modalProp?.unitPrice * productQuantity,
			currencyId: modalProp?.currencyId?._id,
			productId: modalProp._id,
			quantity: productQuantity,
			comment: 'purchasing product',
		});
	};

	return (
		<div className="h-full w-full border border-red-500 ">
			<main className="relative h-[80%] w-full mt-[40%] flex flex-col items-center bg-[#0F121D] rounded-tl-[22px] rounded-tr-[22px] border-2 border-[#ffffff] border-opacity-[8%] ">
				<div className="absolute top-[-3.5rem] z-[500] w-[8rem] h-[8rem] rounded-[32px] bg-[#171928] border border-[#ffffff] border-opacity-[8%] flex items-center justify-center ">
					<img
						src={modalProp?.productImageURL}
						alt=""
						className="w-[80%] h-[80%] rounded-[32px]"
					/>
				</div>
				<div className="w-full h-[90%] mt-[6rem] flex-col items-center justify-center overflow-y-auto">
					<h1 className="text-[#FFFFFF] text-[1.75rem] font-medium mb-2 text-center">
						{modalProp?.title}
					</h1>
					<p className="w-[60%] mx-auto text-[0.875rem] text-[#ffffff] text-opacity-[50%] font-medium text-center">
						Purchase More {modalProp?.productForm} To Boost Your game Experience{' '}
					</p>
					<p className="text-[0.875rem] text-[#848AA0] font-medium text-center mt-6 mb-2">
						Quantity
					</p>
					<div className="flex items-center justify-center gap-3">
						<img
							src={productMinusinactive}
							alt=""
							onClick={() => {
								if (productQuantity > 1) {
									setProductQuantity(productQuantity - 1);
								}
							}}
							className={`${
								productQuantity > 1
									? 'cursor-pointer'
									: 'cursor-default opacity-10'
							}`}
						/>
						<span className="text-[1.25rem] text-primary">
							{productQuantity}
						</span>
						<img
							src={productPlusinactive}
							alt=""
							onClick={() => setProductQuantity(productQuantity + 1)}
							className="cursor-pointer"
						/>
					</div>
					<p className="text-[0.875rem] text-[#848AA0] font-medium text-center mt-6">
						Amount
					</p>
					<h4 className="text-[0.875rem] text-[#E6E8EC] font-medium text-center mb-2">
						{modalProp?.unitPrice * productQuantity} CWR
					</h4>

					<div className="w-[85%] mx-auto">
						{userWallet?.balance?.mainBalance >= modalProp?.unitPrice && (
							<p className="text-[14px] text-text-grey mt-6 mb-2">Pay with </p>
						)}
						{userWallet?.balance?.mainBalance < modalProp?.unitPrice && (
							<p className="text-text-orange text-[14px] mt-6 mb-2">
								Insufficient balance
							</p>
						)}
						<div className=" border-2 border-[#ffffff] border-opacity-[8%] bg-[#ffffff] bg-opacity-[4%] px-4 py-3 rounded-xl flex gap-3 justify-between items-center">
							<div className="h-[1.5rem] md:h-[2.3rem] flex items-center justify-center gap-1 ">
								{userWallet?.balance?.mainBalance >=
								modalProp?.unitPrice * productQuantity ? (
									<div className="flex items-center gap-1">
										<img src={cowryIcon} alt="" className="w-[1.5rem]" />
										<span className="text-[17px] font-normal text-[#ffffff] text-opacity-[50%]">
											{formatMoney(userWallet?.balance?.mainBalance)}
										</span>
									</div>
								) : (
									<span className="text-[1.25rem] font-sembold text-[#ffffff] text-opacity-[50%]">
										Cowries
									</span>
								)}
							</div>

							<Button3d
								text={'Top up'}
								icon={walletIcon}
								onClick={() => {
									modalClose && modalClose();
									setActiveTab && setActiveTab('cowries');
									navigate('/store');
								}}
								className="w-[8.5rem] h-[2.5rem]"
							/>
						</div>

						{userWallet?.balance?.mainBalance >=
							modalProp?.unitPrice * productQuantity && (
							<Button3d
								text="Confirm Purchase"
								loading={isLoading}
								onClick={() => purchaseProduct()}
								className="w-full h-[3.6rem] mt-4"
								textClass="font-mediums"
							/>
						)}
						<div className="flex items-center justify-center gap-2 mt-3 mb-5">
							<span className=" text-text-grey text-[1rem]">Reward -</span>
							<div className="flex items-center gap-1">
								<img src={diamond} alt="" className="w-[1rem] h-[1rem]" />
								<span className="text-text-orange text-[1rem]">
									{modalProp?.unitGemReward * productQuantity} Gems
								</span>{' '}
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};
